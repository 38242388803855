import React, { useState, useEffect, useRef } from "react";
import { Carousel } from "react-bootstrap";
import { Link } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Button } from 'react-bootstrap';

export const Gallery = (props) => {
  const [hasAnimated, setHasAnimated] = useState(false);
  const portfolioRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const element = portfolioRef.current;
      if (element) {
        const rect = element.getBoundingClientRect();
        const inView = rect.top < window.innerHeight && rect.bottom >= 0;
        if (inView && !hasAnimated) {
          setHasAnimated(true);
          window.removeEventListener("scroll", handleScroll); // Remove event listener after animation
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    handleScroll(); // Initial check

    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasAnimated]);

  return (
    <div id="portfolio" className={`text-left ${hasAnimated ? 'animate' : ''}`} ref={portfolioRef}>
      <div className="container-fluid p-0">
        <div className="section-title">
          <div className="penjelasan">
            <h2>MEET PRODUCTION PORTFOLIO</h2>
            <p>
              We do our projects with passion and professionalism to reach what you want in your event!
            </p>
          </div>
           <div className="buttonporto">
            <a href="https://drive.google.com/file/d/1F_6w7HF6YTkFPIj1pMCZ5L52bzowTWx0/view?usp=sharing" download rel="noopener noreferrer" className="text-decoration-none">
              <Button variant="secondary" className="btn-porto">COMPANY PROFILE</Button>
            </a>
          </div>
        </div>
        <Carousel>
          {props.data
            ? props.data.map((d, i) => (
                <Carousel.Item key={`${d.title}-${i}`}>
                  <Link to={`/portofolio?id=${d.id}`}>
                    <img
                      className="d-block w-100"
                      src={d.largeImage}
                      alt={d.title}
                      style={{ cursor: 'pointer' }}
                    />
                  </Link>
                  <Carousel.Caption>
                    <h3>{d.title}</h3>
                  </Carousel.Caption>
                </Carousel.Item>
              ))
            : "Loading..."}
        </Carousel>
      </div>
    </div>
  );
};
